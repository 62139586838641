<template>
    <div  class="content">
        <div class="tool">
           <el-button type="primary" class="el-icon-plus" @click="AddClick"> 新增</el-button>
            <el-button type="primary" class="el-icon-bottom" @click="Send"> 全部下发</el-button>
        </div>

        <el-table :data='FeeListData' border style="width: 100%;font-size:13px" stripe :highlight-current-row='true'>
            <el-table-column align="center" prop="fee_name" label="名称"></el-table-column>
            <el-table-column align="center" prop="fee_type" label="计费方式">
                <template slot-scope="scope">
                    {{scope.row.fee_type===0?'按时计费':(scope.row.fee_type===1?"白天夜间":(scope.row.fee_type===2?"按次收费":"按时段收费"))}}
                </template>
            </el-table-column>
            <el-table-column align="center" prop="pk_name" label="适用车场">
              <template slot-scope="scope">
                     {{BindPark(scope.row)}}
                </template>
            </el-table-column>
            <el-table-column align="center" prop="fee_car_type" label="适用车型">
                <template slot-scope="scope">
                   {{scope.row.fee_car_type===0?'全部':(scope.row.fee_car_type===1?"小车":"大车")}}
                </template>
            </el-table-column>
            <el-table-column align="center" prop="card_name" label="适用卡型">
               <template slot-scope="scope">
                    {{BindCard(scope.row)}}
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-button @click="EditClick(scope.row)" type="primary" size="small" icon="el-icon-edit"></el-button>
                    <el-button @click="DelClick(scope.row)" type="danger" size="small" icon="el-icon-delete"></el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog :title="title" :visible.sync="dialogVisible" width='800px' :append-to-body='true'>
            <el-form :model="feeForm" :rules="rules" ref="feeForm" label-width="140px" size='mini'>
              <el-row :gutter="10">
                <el-col :xs="24" :sm="12">
                  <el-form-item label="名称" prop="fee_name">
                      <el-input v-model="feeForm.fee_name"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12">
                  <el-form-item label="适用车场">
                    <el-select v-model="feeForm.fee_parkId" style="width:100%;">
                        <el-option label="全部" :value='0'></el-option>
                        <el-option v-for="item in ParkDataList" :key="item.pk_id" :label="item.pk_name" :value="item.pk_id"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :xs="24" :sm="12">
                  <el-form-item label="适用卡型">
                      <el-select v-model="feeForm.fee_card_type" style="width:100%;" @change='seleced'>
                        <el-option v-for="item in CardDataList" :key="item.card_id" :label="item.card_name" :value="item.card_id"></el-option>
                      </el-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12">
                  <el-form-item label="适用车型">
                    <el-select v-model="feeForm.fee_car_type" style="width:100%;">
                        <el-option label="全部" :value='0'></el-option>
                        <el-option label="小车" :value='1'></el-option>
                        <el-option label="大车" :value='2'></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :xs="24" :sm="12">
                  <el-form-item label="计费方式">
                      <el-select v-model="feeForm.fee_type" style="width:100%;">
                        <el-option label="按时计费" :value='0'></el-option>
                        <el-option label="白天夜间" :value='1'></el-option>
                        <el-option label="按次计费" :value='2'></el-option>
                        <el-option label="按时段计费" :value='3'></el-option>
                      </el-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12">
                  <el-form-item label=" " :style="{display: (displayShow?'block':'none')}">
                    <el-tooltip class="item" effect="dark" content="不勾选，将所有车场免费" placement="top-start">
                      <el-checkbox v-model="feeForm.fee_longcard_charge">地面扣费，地库免费</el-checkbox>
                    </el-tooltip>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-card>
                <div slot="header" class="clearfix">
                  <span>费率设置</span>
                </div>
                <el-row :gutter="10">
                  <el-col :xs="24" :sm="12">
                    <el-form-item label="免费时长(分钟)">
                        <el-input-number v-model="feeForm.fee_free_time" controls-position="right" :min="0" style="width:100%"></el-input-number>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12">
                    <el-form-item label="超出免费时长后">
                      <el-select v-model="feeForm.fee_include_free_time" style="width:100%;">
                          <el-option label="包含免费时段" :value='true'></el-option>
                          <el-option label="不包含免费时段" :value='false'></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <div v-if="feeForm.fee_type===0">
                    <el-row :gutter="10">
                      <el-col :xs="24" :sm="12">
                        <el-form-item label="首段时长(分钟)">
                            <el-input-number v-model="feeForm.fee_day_time1" controls-position="right" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12">
                        <el-form-item label="收费(元)">
                          <el-input-number v-model="feeForm.fee_day_charge1" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="10">
                      <el-col :xs="24" :sm="12">
                        <el-form-item label="超出时段后每(分钟)">
                            <el-input-number v-model="feeForm.fee_day_time5" controls-position="right" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12">
                        <el-form-item label="收费(元)">
                          <el-input-number v-model="feeForm.fee_day_charge5" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="10">
                      <el-col :xs="24" :sm="12">
                        <el-form-item label="0点后加收过夜费">
                            <el-input-number v-model="feeForm.fee_overnight_charge" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12">
                        <el-form-item label="全天限额(元)">
                          <el-input-number v-model="feeForm.fee_day_limit_charge" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="10">
                      <el-col :xs="24" :sm="12">
                        <el-form-item label="收费达到(元)">
                          <el-input-number v-model="feeForm.fee_charge_free" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12">
                        <el-form-item label="封顶方式">
                          <el-select v-model="feeForm.fee_charge_timespan" style="width:100%;">
                                <el-option label="24小时" :value='0'></el-option>
                                <el-option label="当天" :value='1'></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                </div>
                <div v-if="feeForm.fee_type===1">
                  <el-tabs v-model="activeName" type="card">
                    <el-tab-pane label="白天" name="1">
                         <el-form-item label="白天时段">
                            <el-col :xs="24" :sm="11">
                              <el-time-picker v-model="feeForm.fee_day_begin_time" value-format="yyyy-MM-dd HH:mm:ss" format="HH:mm:ss" style="width:100%"></el-time-picker>
                            </el-col>
                            <el-col :span="2">--</el-col>
                            <el-col :xs="24" :sm="11">
                              <el-time-picker v-model="feeForm.fee_day_end_time" value-format="yyyy-MM-dd HH:mm:ss" format="HH:mm:ss" style="width:100%"></el-time-picker>
                            </el-col>
                        </el-form-item>
                        <el-row :gutter="10">
                            <el-col :xs="24" :sm="12">
                              <el-form-item label="首段时长(分钟)">
                                  <el-input-number v-model="feeForm.fee_day_time1" controls-position="right" :min="0" style="width:100%"></el-input-number>
                              </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12">
                              <el-form-item label="收费(元)">
                                <el-input-number v-model="feeForm.fee_day_charge1" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                              </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="10">
                            <el-col :xs="24" :sm="12">
                              <el-form-item label="超出时段后每(分钟)">
                                  <el-input-number v-model="feeForm.fee_day_time5" controls-position="right" :min="0" style="width:100%"></el-input-number>
                              </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12">
                              <el-form-item label="收费(元)">
                                <el-input-number v-model="feeForm.fee_day_charge5" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                              </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="10">
                            <el-col :xs="24" :sm="12">
                            </el-col>
                            <el-col :xs="24" :sm="12">
                              <el-form-item label="白天收费限额(元)">
                                <el-input-number v-model="feeForm.fee_day_limit_charge" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                              </el-form-item>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="夜间" name="2">
                       <el-row :gutter="10">
                            <el-col :xs="24" :sm="12">
                              <el-form-item label="首段时长(分钟)">
                                  <el-input-number v-model="feeForm.fee_night_time1" controls-position="right" :min="0" style="width:100%"></el-input-number>
                              </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12">
                              <el-form-item label="收费(元)">
                                <el-input-number v-model="feeForm.fee_night_charge1" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                              </el-form-item>
                            </el-col>
                          </el-row>
                          <el-row :gutter="10">
                            <el-col :xs="24" :sm="12">
                              <el-form-item label="超出时段后每(分钟)">
                                  <el-input-number v-model="feeForm.fee_night_time5" controls-position="right" :min="0" style="width:100%"></el-input-number>
                              </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12">
                              <el-form-item label="收费(元)">
                                <el-input-number v-model="feeForm.fee_night_charge5" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                              </el-form-item>
                            </el-col>
                          </el-row>
                          <el-row :gutter="10">
                            <el-col :xs="24" :sm="12">
                            </el-col>
                            <el-col :xs="24" :sm="12">
                              <el-form-item label="夜间收费限额(元)">
                                <el-input-number v-model="feeForm.fee_night_limit_charge" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                              </el-form-item>
                            </el-col>
                          </el-row>
                    </el-tab-pane>
                    <el-row>
                       <el-col :xs="24" :sm="12">
                              <el-form-item label="24小时限额">
                                <el-tooltip class="item" effect="dark" content="为0,表示不限制" placement="top-start">
                                  <el-input-number v-model="feeForm.fee_charge_free" controls-position="right" :min="0" style="width:100%"></el-input-number>
                                </el-tooltip>
                              </el-form-item>
                        </el-col>
                    </el-row>
                  </el-tabs>
                </div>
                <div v-if="feeForm.fee_type===2">
                  <el-form-item label="每次收费(元)">
                        <el-input-number v-model="feeForm.fee_day_charge1" controls-position="right" :min="0" style="width:100%"></el-input-number>
                  </el-form-item>
                  <el-row :gutter="10">
                      <el-col :xs="24" :sm="12">
                        <el-form-item label="收费达到(元)">
                          <el-input-number v-model="feeForm.fee_charge_free" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12">
                        <el-form-item label="封顶方式">
                          <el-select v-model="feeForm.fee_charge_timespan" style="width:100%;">
                                <el-option label="24小时" :value='0'></el-option>
                                <el-option label="当天" :value='1'></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                  </el-row>
                </div>
                <div v-if="feeForm.fee_type===3">
                  <el-row :gutter="10">
                      <el-col :xs="24" :sm="12"  :md="8">
                        <el-form-item label="1小时(元)">
                          <el-input-number v-model="feeForm.fee_charge1" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="8">
                        <el-form-item label="9小时(元)">
                          <el-input-number v-model="feeForm.fee_charge9" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="8">
                        <el-form-item label="17小时(元)">
                          <el-input-number v-model="feeForm.fee_charge17" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                  </el-row>
                  <el-row :gutter="10">
                      <el-col :xs="24" :sm="12"  :md="8">
                        <el-form-item label="2小时(元)">
                          <el-input-number v-model="feeForm.fee_charge2" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="8">
                        <el-form-item label="10小时(元)">
                          <el-input-number v-model="feeForm.fee_charge10" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="8">
                        <el-form-item label="18小时(元)">
                          <el-input-number v-model="feeForm.fee_charge18" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                  </el-row>
                  <el-row :gutter="10">
                      <el-col :xs="24" :sm="12"  :md="8">
                        <el-form-item label="3小时(元)">
                          <el-input-number v-model="feeForm.fee_charge3" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="8">
                        <el-form-item label="11小时(元)">
                          <el-input-number v-model="feeForm.fee_charge11" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="8">
                        <el-form-item label="19小时(元)">
                          <el-input-number v-model="feeForm.fee_charge19" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                  </el-row>
                  <el-row :gutter="10">
                      <el-col :xs="24" :sm="12"  :md="8">
                        <el-form-item label="4小时(元)">
                          <el-input-number v-model="feeForm.fee_charge4" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="8">
                        <el-form-item label="12小时(元)">
                          <el-input-number v-model="feeForm.fee_charge12" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="8">
                        <el-form-item label="20小时(元)">
                          <el-input-number v-model="feeForm.fee_charge20" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                  </el-row>
                  <el-row :gutter="10">
                      <el-col :xs="24" :sm="12"  :md="8">
                        <el-form-item label="5小时(元)">
                          <el-input-number v-model="feeForm.fee_charge5" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="8">
                        <el-form-item label="13小时(元)">
                          <el-input-number v-model="feeForm.fee_charge13" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="8">
                        <el-form-item label="21小时(元)">
                          <el-input-number v-model="feeForm.fee_charge21" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                  </el-row>
                  <el-row :gutter="10">
                      <el-col :xs="24" :sm="12"  :md="8">
                        <el-form-item label="6小时(元)">
                          <el-input-number v-model="feeForm.fee_charge6" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="8">
                        <el-form-item label="14小时(元)">
                          <el-input-number v-model="feeForm.fee_charge14" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="8">
                        <el-form-item label="22小时(元)">
                          <el-input-number v-model="feeForm.fee_charge22" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                  </el-row>
                  <el-row :gutter="10">
                      <el-col :xs="24" :sm="12"  :md="8">
                        <el-form-item label="7小时(元)">
                          <el-input-number v-model="feeForm.fee_charge7" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="8">
                        <el-form-item label="15小时(元)">
                          <el-input-number v-model="feeForm.fee_charge15" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="8">
                        <el-form-item label="23小时(元)">
                          <el-input-number v-model="feeForm.fee_charge23" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                  </el-row>
                  <el-row :gutter="10">
                      <el-col :xs="24" :sm="12"  :md="8">
                        <el-form-item label="8小时(元)">
                          <el-input-number v-model="feeForm.fee_charge8" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="8">
                        <el-form-item label="16小时(元)">
                          <el-input-number v-model="feeForm.fee_charge16" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="8">
                        <el-form-item label="24小时(元)">
                          <el-input-number v-model="feeForm.fee_charge24" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                      </el-col>
                  </el-row>
                </div>
              </el-card>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import util from '../../util/util'

export default {
  data: function () {
    return {
      screenWidth: 0,
      activeName: '1',
      FeeListData: [],
      ParkDataList: [],
      CardDataList: [],
      dialogVisible: false,
      displayShow:false,
      title: '',
      feeForm: {
        fee_id: 0,
        fee_name: '',
        fee_parkId: 0,
        fee_type: 0,
        fee_card_type: 1,
        fee_car_type: 0,
        fee_longcard_charge:false,
        fee_free_time: 30,
        fee_include_free_time: false,
        fee_day_begin_time: new Date(2016, 9, 10, 7, 0, 0),
        fee_day_end_time: new Date(2016, 9, 10, 21, 59, 59),
        fee_day_time1: 0,
        fee_day_time2: 0,
        fee_day_time5: 60,
        fee_day_charge1: 0.00,
        fee_day_charge2: 0.00,
        fee_day_charge5: 1.00,
        fee_overnight_charge: 0.00,
        fee_day_limit_charge: 30.00,
        fee_night_time1: 0,
        fee_night_time2: 0,
        fee_night_time5: 60,
        fee_night_charge1: 0.00,
        fee_night_charge2: 0.00,
        fee_night_charge5: 1.00,
        fee_night_limit_charge: 30.00,
        fee_charge_free: 0.00,
        fee_charge_timespan: 0,
        fee_charge1: 0.00,
        fee_charge2: 0.00,
        fee_charge3: 0.00,
        fee_charge4: 0.00,
        fee_charge5: 0.00,
        fee_charge6: 0.00,
        fee_charge7: 0.00,
        fee_charge8: 0.00,
        fee_charge9: 0.00,
        fee_charge10: 0.00,
        fee_charge11: 0.00,
        fee_charge12: 0.00,
        fee_charge13: 0.00,
        fee_charge14: 0.00,
        fee_charge15: 0.00,
        fee_charge16: 0.00,
        fee_charge17: 0.00,
        fee_charge18: 0.00,
        fee_charge19: 0.00,
        fee_charge20: 0.00,
        fee_charge21: 0.00,
        fee_charge22: 0.00,
        fee_charge23: 0.00,
        fee_charge24: 0.00
      },
      rules: {
        fee_name: [{ required: true, message: '请输入费率名称', trigger: 'blur' }]
      },
      isFinish: true
    }
  },
  computed: {
    BindPark () {
      return function (row) {
        var name = '全部'
        var arr = this.ParkDataList.filter(a => a.pk_id === row.fee_parkId)
        if (arr.length > 0) {
          name = arr[0].pk_name
        }
        return name
      }
    },
    BindCard () {
      return function (row) {
        var name = '临时车'
        var arr = this.CardDataList.filter(a => a.card_id === row.fee_card_type)
        if (arr.length > 0) {
          name = arr[0].card_name
        }
        return name
      }
    }
  },
  created () {
    this.screenWidth = document.body.clientWidth
    this.GetFeeDataList()
  },
  methods: {
    GetFeeDataList () {
      util.Get('fee/getfeedatalist').then(res => {
        if (res.rpStatus === 10000) {
          this.FeeListData = res.list[0]
          this.ParkDataList = res.list[1].filter(a=>a.pk_sub_id===0)
          this.CardDataList = res.list[2].filter(a => a.card_limit !== 5)
        }
      })
    },
    AddClick () {
      if(util.CheckUserAuth('1-4-1')){
        this.feeForm.fee_id = 0
        this.feeForm.fee_name = ''
        this.feeForm.fee_parkId = 0
        this.feeForm.fee_longcard_charge=false
        this.feeForm.fee_type = 0
        this.feeForm.fee_card_type = 1
        this.feeForm.fee_car_type = 0
        this.feeForm.fee_free_time = 30
        this.feeForm.fee_include_free_time = false
        this.feeForm.fee_day_begin_time = util.FormateDate(new Date(2016, 9, 10, 7, 0, 0),2)
        this.feeForm.fee_day_end_time = util.FormateDate(new Date(2016, 9, 10, 21, 59, 59),2)
        this.feeForm.fee_day_time1 = 0
        this.feeForm.fee_day_time2 = 0
        this.feeForm.fee_day_time5 = 60
        this.feeForm.fee_day_charge1 = 0.00
        this.feeForm.fee_day_charge2 = 0.00
        this.feeForm.fee_day_charge5 = 1.00
        this.feeForm.fee_overnight_charge = 0.00
        this.feeForm.fee_day_limit_charge = 30.00
        this.feeForm.fee_night_time1 = 0
        this.feeForm.fee_night_time2 = 0
        this.feeForm.fee_night_time5 = 60
        this.feeForm.fee_night_charge1 = 0.00
        this.feeForm.fee_night_charge2 = 0.00
        this.feeForm.fee_night_charge5 = 1.00
        this.feeForm.fee_night_limit_charge = 30.00
        this.feeForm.fee_charge_free = 0.00
        this.feeForm.fee_charge_timespan = 0
        this.feeForm.fee_charge1 = 0.00
        this.feeForm.fee_charge2 = 0.00
        this.feeForm.fee_charge3 = 0.00
        this.feeForm.fee_charge4 = 0.00
        this.feeForm.fee_charge5 = 0.00
        this.feeForm.fee_charge6 = 0.00
        this.feeForm.fee_charge7 = 0.00
        this.feeForm.fee_charge8 = 0.00
        this.feeForm.fee_charge9 = 0.00
        this.feeForm.fee_charge10 = 0.00
        this.feeForm.fee_charge11 = 0.00
        this.feeForm.fee_charge12 = 0.00
        this.feeForm.fee_charge13 = 0.00
        this.feeForm.fee_charge14 = 0.00
        this.feeForm.fee_charge15 = 0.00
        this.feeForm.fee_charge16 = 0.00
        this.feeForm.fee_charge17 = 0.00
        this.feeForm.fee_charge18 = 0.00
        this.feeForm.fee_charge19 = 0.00
        this.feeForm.fee_charge20 = 0.00
        this.feeForm.fee_charge21 = 0.00
        this.feeForm.fee_charge22 = 0.00
        this.feeForm.fee_charge23 = 0.00
        this.feeForm.fee_charge24 = 0.00
        this.dialogVisible = true
        this.displayShow=false
        this.title = '新增'
        
      }
    },
    EditClick (row) {
      if(util.CheckUserAuth('1-4-2')){
        var form = JSON.parse(JSON.stringify(row))
        form.fee_day_begin_time = util.FormateDate(row.fee_day_begin_time,2)
        form.fee_day_end_time = util.FormateDate(row.fee_day_end_time,2)
        this.feeForm = form
        this.dialogVisible = true
        this.title = '修改'
        this.seleced(row.fee_card_type)
      }
    },
    DelClick (row) {
      if(util.CheckUserAuth('1-4-3')){
        this.$confirm('确定删除费率“' + row.fee_name + '”？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          util.Get('fee/del?id=' + row.fee_id+'&name='+row.fee_name).then(res => {
            if (res.rpStatus === 10000) { this.GetFeeDataList() } else { this.$message.error(res.rpMsg) }
          })
        }).catch(e => e)
      }
    },
    submit () {
      this.$refs.feeForm.validate((valid) => {
        if (valid) {
          util.Post('fee/edit', this.feeForm).then(res => {
            if (res.rpStatus === 10000) {
              this.GetFeeDataList()
              this.dialogVisible = false
            } else { this.$message.error(res.rpMsg) }
          })
        }
      })
    },
    Send () {
      if (this.isFinish) {
        this.isFinish=false
        util.Send('fee').then(res=>{
          this.isFinish=true
        })
      }
    },
    seleced(value){
      this.displayShow=false
      var arr = this.CardDataList.filter(a => a.card_id === value)
        if (arr.length > 0) {
          if(arr[0].card_limit===0||arr[0].card_limit===6){
            this.displayShow=true
          }
        }
    }
  }
}
</script>

<style scoped>
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.el-table{
    margin-top: 10px;
}
.d{
  display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
::v-deep .el-dialog{
  --el-bg-color:#000741 !important;
}
::v-deep .el-dialog__header{
  --el-text-color-primary:#1effff;
  --el-text-color-regular:#fff;
  padding: 0px !important;
  background-color: #309CE2;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-dialog__title{
    color: white;
}

::v-deep .el-dialog__headerbtn .el-dialog__close{
    color: white;
} 
</style>
